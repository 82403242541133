
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    uidb64: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tokenStatus: 'checking' as 'checking' | 'valid' | 'invalid' | 'error',
    formFields: {
      new_password1: '',
      new_password2: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.checkPasswordResetLink();
  },
  methods: {
    async checkPasswordResetLink() {
      this.tokenStatus = 'checking';

      const responseData = await this.api({
        url: 'users/check_password_reset_link/',
        method: 'POST',
        json: {
          uidb64: this.uidb64,
          token: this.token,
        },
      });

      if (responseData.status === 200) {
        this.tokenStatus = responseData.body.valid ? 'valid' : 'invalid';
      } else {
        this.tokenStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/password_reset_submission/',
        method: 'POST',
        json: {
          uidb64: this.uidb64,
          token: this.token,
          new_password1: this.formFields.new_password1,
          new_password2: this.formFields.new_password2,
        },
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
