<template>
  <div class="horizontal-padding">
    <template v-if="tokenStatus === 'invalid'">
      <h1>Password Reset Unsuccessful</h1>

      <alert variant="danger">
        The password reset link was invalid, possibly because it has already
        been used. Please
        <router-link
          :to="{name: 'password_reset'}"
          class="alert-link"
        >
          request a new password reset
        </router-link>
      </alert>
    </template>

    <template v-else-if="formSuccess">
      <h1>Password Reset Complete</h1>

      <alert variant="success">
        Your password has been set. You may go ahead and
        <router-link
          :to="{ name: 'login' }"
          class="alert-link"
        >log in</router-link> now. <!-- eslint-disable-line vue/multiline-html-element-content-newline, max-len -->
      </alert>
    </template>

    <template v-else>
      <h1>Enter a New Password</h1>

      <spinner
        v-if="tokenStatus === 'checking'"
        preset="large"
      />

      <template v-else-if="tokenStatus === 'valid'">
        <p class="text-center">
          Please enter your new password twice so we can verify you typed it in
          correctly.
        </p>

        <alert
          v-if="formErrors.non_field_errors !== undefined"
          variant="danger"
          class="js-form-error"
          dismissible
          @dismissed="formErrors = {}"
        >
          {{ formErrors.non_field_errors[0] }}
        </alert>

        <form @submit.prevent="submitForm">
          <div class="form-group">
            <label for="new_password1">New password</label>
            <input
              id="new_password1"
              v-model="formFields.new_password1"
              :class="{ 'is-invalid': formErrors.new_password1 !== undefined }"
              type="password"
              minlength="8"
              required
              aria-describedby="new_password1_help"
            >
            <small
              id="new_password1_help"
              class="form-text"
            >
              Your password must contain at least 8 characters.
            </small>
            <div
              v-if="formErrors.new_password1 !== undefined"
              class="invalid-feedback js-form-error"
            >
              {{ formErrors.new_password1[0] }}
            </div>
          </div>

          <div class="form-group">
            <label for="new_password2">New password confirmation</label>
            <input
              id="new_password2"
              v-model="formFields.new_password2"
              :class="{ 'is-invalid': formErrors.new_password2 !== undefined }"
              type="password"
              minlength="8"
              required
            >
            <div
              v-if="formErrors.new_password2 !== undefined"
              class="invalid-feedback js-form-error"
            >
              {{ formErrors.new_password2[0] }}
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary"
            :disabled="formSubmitting"
          >
            <template v-if="formSubmitting">
              Setting New Password
              <spinner />
            </template>

            <template v-else>
              Set New Password
            </template>
          </button>
        </form>
      </template>

      <template v-else-if="tokenStatus === 'error'">
        <alert variant="danger">
          Unable to communicate with the server. Please check your connection
          and try again.
        </alert>

        <div class="try-again-button-container">
          <button
            type="button"
            class="btn btn-outline-primary"
            @click="checkPasswordResetLink"
          >
            Try Again
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    uidb64: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    tokenStatus: 'checking' as 'checking' | 'valid' | 'invalid' | 'error',
    formFields: {
      new_password1: '',
      new_password2: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
    formSuccess: false,
  }),
  watch: {
    formErrors() {
      if (!this.isObjectEmpty(this.formErrors)) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  created() {
    this.checkPasswordResetLink();
  },
  methods: {
    async checkPasswordResetLink() {
      this.tokenStatus = 'checking';

      const responseData = await this.api({
        url: 'users/check_password_reset_link/',
        method: 'POST',
        json: {
          uidb64: this.uidb64,
          token: this.token,
        },
      });

      if (responseData.status === 200) {
        this.tokenStatus = responseData.body.valid ? 'valid' : 'invalid';
      } else {
        this.tokenStatus = 'error';
      }
    },
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'users/password_reset_submission/',
        method: 'POST',
        json: {
          uidb64: this.uidb64,
          token: this.token,
          new_password1: this.formFields.new_password1,
          new_password2: this.formFields.new_password2,
        },
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.formSuccess = true;
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style scoped>
  .alert,
  .try-again-button-container {
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    max-width: 100%;
  }

  form {
    margin: 0 auto 1rem;
    width: 290px;
  }
</style>
